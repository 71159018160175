import { FeedDetail, PostType } from '@/pinia/cf-post/type'

export type FetchRequest = {
	offset: number
	limit: number
	pageId: string
}

export interface ProductSet {
	productId: string
	name: string
	sku: string
	price: number
	unit: number
}

export interface Product {
	_id: string
	name: string
	enable: boolean
	product: string // productId
	productSet?: ProductSet[]
	price: number
	cfCode: string
	quantity: number
	sold: number
	deleted: boolean
	deletedAt: Date | null
}

export type FetchPostInPageRequest = {
	offset: number
	limit: number
	pageId: string
	afterToken: string | null
	postCFId: string
}

export enum SHIPMENT_STATUS {
	FREE = 'FREE',
	FLAT_RATE = 'FLAT_RATE',
}

export enum POST_CF_STATUS {
	PROCESS = 'PROCESS',
	COMPLETED = 'COMPLETED',
}

export interface CFPostDetailResponse {
	_id: string
	postIds: string[]
	posts: FeedDetail[]
	deleted: boolean
	deletedAt: null
	type: PostType
	status: POST_CF_STATUS
	name: string
	pageId: string
	messageReply: string
	imageUrl: string
	imageReply: string
	isLikeComment: boolean
	isHideComment: boolean
	enable: boolean
	createdAt: Date
	updatedAt: Date
	courierId: string
	shopId: string
	payment: string[]
	bank: string[]
	shipment: {
		status: SHIPMENT_STATUS
		flatRatePrice: number
	}
	products: Product[] | IProductListRes[]
}

export interface ISubProductsRes {
	createdAt?: string
	deleted?: boolean
	deletedAt?: string | null
	images: string[]
	parent?: string
	price: number
	shopId?: string
	sku: string
	types: string[]
	unit: number
	updatedAt?: string
	variations?: string[]
	weight: number
}

export interface IProductListRes {
	_id?: string
	name: string
	desc: string
	sku: string
	weight: number
	price: number
	unit: number
	quantity?: number | undefined
	shopId?: string
	images: [string] | string[] | []
	createdAt?: string
	updatedAt?: string
	enable?: boolean
	cfCode?: string
	errorCf?: string
	product?:
		| {
				createdAt?: string
				desc: string
				images: [string] | string[] | []
				name?: string
				price: number
				shopId?: string
				sku: string
				quantity?: number
				unit: 121
				updatedAt?: string
				weight: 123
				_id?: string
		  }
		| ISubProductsRes
	variations?: { _id: string; name: string; list: string[] }[]
	subProducts?: Array<ISubProductsRes>
}

export interface CreatePayload {
	name: string
	type: string
	pageId: string
	isLikeComment: boolean
	isHideComment: boolean
	messageReply: string
	courierId: string
	shopId: string
	payment: string[]
	bank: string[]
	products: Product[]
	shipment: {
		status: SHIPMENT_STATUS
		flatRatePrice: number
	}
	postIds: string[]
	imageReply?: File | string | null
	imageUrl?: string | null
}

export interface EditPayload extends CreatePayload {
	postCFId: string
}

export interface GetOrderParams {
	offset: number
	limit: number
	status: null | string
}
